<template>
    <div id="navigation">
        <b-container>
            <b-nav v-if="! dropDownMenu"
                   class="d-flex justify-content-center"
            >
                <b-nav-item
                    v-for="item of items"
                    :key="item.id"
                    v-scroll-to="{ el: item.id, offset: -75 }"
                >
                    {{item.text}}
                </b-nav-item>

                <b-nav-item>
                    <router-link to="impressum">Impressum</router-link>
                </b-nav-item>
            </b-nav>

            <b-nav-item-dropdown
                    v-if="dropDownMenu"
                    class="m-n3"
                    text=""
            >
                <b-dropdown-item
                        v-for="item of dropdownitems"
                        :key="item.id"
                        v-scroll-to="{ el: item.id, offset: -75 }"
                >
                    {{item.text}}
                </b-dropdown-item>
                <b-dropdown-item href="#/impressum">Impressum</b-dropdown-item>
            </b-nav-item-dropdown>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "NavigationSection",
        props: {
            activeLink: String,
            dropDownMenu: Boolean
        },
        data () {
            return {
                items: [
                    { id: '#section-offer', text: 'Therapie' },
                    { id: '#section-how', text: 'Ablauf' },
                    { id: '#section-contact', text: 'Kontakt' }
                ],
                dropdownitems: [
                    { id: '#section-introduction', text: 'Vorwort' },
                    { id: '#section-offer', text: 'Therapie' },
                    { id: '#section-how', text: 'Ablauf' },
                    { id: '#section-contact', text: 'Kontakt' },
                    { id: '#section-education', text: 'Werdegang' },
                ]
            }
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0,0);
            }
        },
        computed: {
            showBackToTopButton: function () {
                return window.scrollY > 900 ? true : false;
            }
        }
    }
</script>

<style scoped>
    .active-link {
        text-decoration: underline;
    }
</style>
