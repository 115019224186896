<template>
  <div>
    <!-- go to MainView.vue for main html, here is only view routing depending on url  with vue-router module -->
    <router-view></router-view>
  </div>
</template>

<script>

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

export default {
  name: 'App',
  components: {}
}
</script>

<style>
  /* remove focus square from elements */
  *:focus {
    outline: none;
  }

  body {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 10px;
    margin-bottom: 10em;
  }

  a {
    color: #006979;
  }

  blockquote {
    padding: 0 3em;
  }

  @media screen and (min-width: 780px) {
    section {
      padding-top: 100px;
    }
  }

  @media screen and (max-width: 779px) and (min-width: 565px) {
    section {
      padding-top: 100px;
    }
  }

  @media screen and (max-width: 564px) {
    section {
      padding-top: 10px;
    }
  }

  h1 {
    color: #006979;
  }

  h2 {
    margin-bottom: 1em;
    color: #2FA963;
  }

  li {
    line-height: 1.5;
  }

  p {
    line-height: 2;
  }
</style>
