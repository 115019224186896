<template>
    <div id="section-education">
        <b-container>
            <b-row>
                <b-col class="col-8 offset-sm-1 offset-1">
                    <h2>Werdegang</h2>
                </b-col>
            </b-row>

            <b-row class="mb-4">
                    <b-col class="offset-1 col-auto col-md-4">
                        <b-img-lazy thumbnail fluid style="height: 15em" :src="require('@/assets/img/bewerbung.webp')"/>
                    </b-col>
                    <b-col class="offset-1 col-4 d-none d-sm-none d-lg-inline-block">
                        <b-img-lazy class="" fluid style="height: 15em"
                                    :src="require('@/assets/img/blindensee-weg.webp')"/>
                    </b-col>
            </b-row>

            <b-row class="offset-1">
                <b-col class="col-md-5 col-10">
                    <b-row class="d-flex justify-content-left">
                        <b-col class="content-field col-3">
                            <p class="date-time">2020</p>
                        </b-col>

                        <b-col class="content-field col-9">
                            <p>
                                Eröffnung der Praxis
                            </p>
                        </b-col>

                        <b-col class="content-field col-3">
                            <p class="date-time">2019</p>
                        </b-col>

                        <b-col class="content-field col-9">
                            <p>
                                Privatinum in Bad-Dürrheim
                            </p>
                        </b-col>

                        <b-col class="content-field col-3">
                            <p class="date-time">2018-19</p>
                        </b-col>

                        <b-col class="content-field col-9">
                            <p>
                                Michael-Balint-Klinik, Königsfeld
                            </p>
                        </b-col>

                        <b-col class="content-field col-3">
                            <p class="date-time">2018</p>
                        </b-col>

                        <b-col class="content-field col-9">
                            <p>
                                Approbation zur Psychologischen Psychotherapeutin
                            </p>
                        </b-col>

                        <b-col class="content-field col-3">
                            <p class="date-time">2015-18</p>
                        </b-col>

                        <b-col class="content-field col-9">
                            <p>
                                Psychotherapeutische Ambulanz am Institut
                                für Psychologie in Freiburg
                            </p>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col class="col-md-5 col-10">
                    <b-row class="d-flex justify-content-left">
                        <b-col class="content-field col-3">
                            <p class="date-time">2014-16</p>
                        </b-col>

                        <b-col class="content-field col-9">
                            <p>
                                Michael Balint Klinik in Königsfeld
                            </p>
                        </b-col>

                        <b-col class="content-field col-3">
                            <p class="date-time">2013-14</p>
                        </b-col>

                        <b-col class="content-field col-9">
                            <p>
                                Klinik für Psychiatrie und Psychotherapie in Freiburg
                            </p>
                        </b-col>

                        <b-col class="content-field col-3">
                            <p class="date-time">2009-13</p>
                        </b-col>

                        <b-col class="content-field col-9">
                            <p>
                                Michael-Balint-Klinik in Königsfeld
                            </p>
                        </b-col>

                        <b-col class="content-field col-3">
                            <p class="date-time">2008</p>
                        </b-col>

                        <div class="content-field col-9">
                            <p>
                                Abschluss zur Diplom-Psychologin an der Albert-Ludwigs-Universität in Freiburg
                            </p>
                        </div>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "WerdegangSection",
    }
</script>

<style scoped>
    #section-education {
        text-align: left;
    }

    .content-field {
        text-align: left;
    }

    .date-time {
        text-align: left;
        margin-right: 2px;
    }
</style>
