<template>
    <header>
        <div class="d-flex justify-content-center mb-sm-5">
            <BilderKaroussel>
                <b-carousel-slide lazy :img-src="require('@/assets/img/blindensee2.webp')"></b-carousel-slide>

                <b-carousel-slide lazy
                                  :img-src="require('@/assets/img/seats2.webp')">
                </b-carousel-slide>

                <b-carousel-slide lazy :img-src="require('@/assets/img/seats3.webp')"></b-carousel-slide>
                <b-carousel-slide lazy :img-src="require('@/assets/img/forest2.webp')"></b-carousel-slide>
                <b-carousel-slide lazy :img-src="require('@/assets/img/parkinglot.webp')" />                    >
                <b-carousel-slide lazy :img-src="require('@/assets/img/praxisvonaussen.webp')" />                    >

            </BilderKaroussel>
        </div>

        <b-container class="fit-image" style="">
            <div class="row d-flex justify-content-center">
                <div class="col-md-5 col-sm-12 col-12 my-auto">
                    <blockquote class="blockquote p-0 my-3 my-sm-0">
                        <p class="my-auto">
                            Willkommen auf der Webseite der <strong>Praxis Wild</strong> für Psychotherapie in
                            Schönwald.
                            Unter
                            <a href="#section-contact">Kontakt</a>
                            finden Sie alle Informationen um einen Termin zu vereinbaren.
                        </p>
                        <footer class="blockquote-footer">
                            Dubravka Wild, Psychotherapeutin <cite title="Source Title"></cite>
                        </footer>
                    </blockquote>
                </div>

                <div class="col-md-5 col-sm-12 col-10 my-auto">
                    <b-img fluid
                           :src="require('../assets/img/dwild.webp')"
                           alt="Bild der Therapeutin">
                    </b-img>
                </div>
            </div>
        </b-container>
    </header>
</template>

<script>
    import BilderKaroussel from "./bilder-karoussel";

    export default {
        name: "BannerSection",
        components: {BilderKaroussel}
    }
</script>

<style scoped>
    blockquote {
        margin: auto;
    }
</style>
