<template>
    <div id="app">

        <ueberschrift-section />

        <banner-section />

        <section id="section-introduction">
            <willkommen-section />
        </section>

        <section id="section-offer">
            <therapie-section />
        </section>

        <section id="section-how">
            <ablauf-section />
        </section>


        <section id="section-details">
            <details-section />
        </section>

        <section id="section-education">
            <werdegang-section />
        </section>

        <section>
            <bilder-section />
        </section>

        <section id="section-contact">
            <kontakt-section />
        </section>
    </div>
</template>

<script>
    import BannerSection from "@/components/banner-section";
    import UeberschriftSection from "@/components/ueberschrift-section";
    import BilderSection from "../components/bilder-karoussel";
    import KontaktSection from "../components/kontakt-section";
    import WillkommenSection from "../components/willkommen-section";
    import TherapieSection from "../components/therapie-section";
    import AblaufSection from "../components/ablauf-section";
    import DetailsSection from "../components/details-section";
    import WerdegangSection from "../components/werdegang-section";

    export default {
        name: 'MainView',
        components: {
            WerdegangSection,
            DetailsSection,
            AblaufSection,
            TherapieSection,
            WillkommenSection,
            KontaktSection,
            BilderSection,
            UeberschriftSection,
            BannerSection,
        },
        methods: {
        }
    }
</script>

<style scoped>

</style>
