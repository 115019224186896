<template>
    <div id="section-contact">
        <b-container>
            <b-row class="d-flex justify-content-center col-10">
                <b-col class="col-md-5 col-10">
                  <h2>Kontakt</h2>
                  <p>
                      <strong>Diplom-Psychologin Dubravka Wild</strong> <br>
                      Psychologische Psychotherapeutin <br>
                      Termine nach Vereinbarung über:<br>
                      <i style="float: left" class="fas fa-phone mr-2"></i>
                      +49 157 3457 0368 <br>
                      <i style="float: left" class="fas fa-at mr-2"></i>
                      <a href="mailto:praxis@psychotherapiewild.de">praxis@psychotherapiewild.de</a> <br>
                      Terminvereinbarung:
                      <b-badge
                              v-b-popover.hover.top=
                                        "'Sie erreichen mich persönlich\n'+
                                        ' dienstags zwischen 10:00 und 12:00 Uhr\n'"
                              title="Erreichbarkeit">
                          Sprechzeiten
                      </b-badge>
                      <br>
                      Alle Kassen und Privat <br>
                  </p>
                  <i style="float: left" class="fas fa-map mr-2"></i>
                  <h5>Adresse</h5>
                  <p class="text-field">
                      Robert-Gerwig-Str. 19 <br>
                      78141 Schönwald <br>
                  </p>
                  <div class="text-field-button">
                      <b-button
                              href="https://goo.gl/maps/oNV6PYpNMT77wnzi6"
                              type="button"
                              class="btn btn-primary mt-2"
                              target="_blank"
                      >
                          <span style="color:white">Route berechnen</span>
                      </b-button>
                  </div>
              </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "kontakt-section",
    }
</script>

<style scoped>
    #section-contact {
        text-align: left;
    }

    .btn {
        color: #2FA963;
        background-color: #2FA963;
        border-color: lightgreen;
    }

    .badge {
        background-color: #2FA963;
    }
</style>
