<template>
    <b-container style="margin-bottom: 2em" >
        <b-row class="justify-content-center d-none d-md-flex">
            <b-col class="col-10">
                <ImageCarousel>
                    <slot/>
                </ImageCarousel>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import ImageCarousel from "./subcomponents/image-carousel";
    export default {
        name: "BilderKaroussel",
        components: {ImageCarousel}
    }
</script>

<style scoped>
</style>
