<template>
    <div id="image-carousel">
        <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="6000"
                controls
                background="#ababab"
                img-width="1024"
                img-height="480"
                style="text-shadow: 1px 1px 2px #333;"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
        >
            <slot>
            </slot>

        </b-carousel>
    </div>
</template>

<script>
    export default {
        name: 'ImageCarousel',
        data() {
            return {
                slide: 0,
                sliding: null
            }
        },
        methods: {
            onSlideStart() {
                this.sliding = true
            },
            onSlideEnd() {
                this.sliding = false
            }
        }
    }
</script>

<style scoped>
</style>
