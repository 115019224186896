<template>
    <div id="section-details">
        <b-container>
            <b-row>
                <b-col class="col-8 offset-sm-1 offset-1">
                    <h2>Details</h2>
                </b-col>
            </b-row>

            <b-row class="d-flex justify-content-center">
                <b-col class="col-sm-5 col-10">
                    <div class="content-field">
                        <i style="float: left" class="fas fa-clock mr-2"></i>
                        <h5>Therapieart</h5>
                        <ul>
                            <li>
                                Einzeltherapie
                            </li>
                            <li>
                                Verhaltenstherapie, Kurzzeittherapie, Traumatherapie
                            </li>
                        </ul>
                    </div>
                </b-col>

                <b-col class="col-sm-5 col-10">
                    <div class="content-field">
                        <i style="float: left" class="fas fa-receipt mr-2"></i>
                        <h5>Abrechnung</h5>
                        <ul>
                            <li>Gesetzliche Krankenversicherung</li>
                            <li>Private Krankenversicherung</li>
                            <li>Beihilfe</li>
                            <li>Selbstzahler</li>
                        </ul>
                    </div>
                </b-col>
            </b-row>

            <b-row class="d-flex justify-content-center">
                <b-col class="col-sm-5 col-10">
                    <div class="content-field">
                        <i style="float: left" class="fas fa-calendar-check mr-2"></i>
                        <h5>Wartezeiten</h5>
                        <ul>
                            <li>
                                In dringenden
                                Fällen sind auch kurzfristig Termine möglich.
                            </li>
                        </ul>
                    </div>
                </b-col>
                <b-col class="col-sm-5 col-10">
                    <div class="content-field">
                        <i style="float: left" class="fas fa-project-diagram mr-2"></i>
                        <h5>Mitgliedschaften</h5>
                        <ul>
                            <li>Kassenärztliche Vereinigung <br> Baden-Württemberg</li>
                            <li>Landespsycho­thera­peuten­kammer <br> Baden-Württemberg</li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "details-section"
    }
</script>

<style scoped>
    #section-details {
        text-align: left;
    }

    ul {
        margin-left: -25px;
    }
</style>
