import Vue from 'vue'
import VueRouter from 'vue-router'

import App from './App.vue'
import ImpressumView from './views/ImpressumView.vue';
import MainView from "@/views/MainView";

import BootstrapVue from './modules/bootstrap-vue.js'
const VueScrollTo = require('vue-scrollto')

Vue.config.productionTip = false;
Vue.config.debug = process.env.VUEJS_DEBUG === 1;

//Vue.prototype.window = window;

Vue.use(VueRouter);
Vue.use(VueScrollTo);

// If using a module system (e.g. via vue-cli), import Vue and VueRouter
// and then call `Vue.use(VueRouter)`.

// Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// `Vue.extend()`, or just a component options object.
const routes = [
  { path: '/', component: MainView },
  { path: '/impressum', component: ImpressumView },
  ];

// Create the router instance and pass the `routes` option
// You can pass in additional options here
const router = new VueRouter({
  routes // short for `routes: routes`
});

// Create and mount the root instance.
// Make sure to inject the router with the router option to make the
// whole app router-aware.
new Vue({
  router,
  components: {
    App,
    BootstrapVue
  },
  render: h => h(App),
}).$mount('#app');

// Now the app has started!

