<template>
    <div id="section-offer">
        <b-container>
            <b-row class="">
                <b-col class="col-8 offset-sm-1 offset-1">
                    <h2>Therapie</h2>
                </b-col>
            </b-row>

            <b-row class="d-flex justify-content-center">
                <b-col class="col-sm-5 col-10">
                    <div class="content-field" style="list-style: none; ">
                        <li>Allgemeine psychische Probleme</li>
                        <li>Ängste und Panik, Phobien</li>
                        <li>Depressionen</li>
                        <li>Trauma, Gewalt, Missbrauch</li>
                        <li>Zwangserkrankungen</li>
                        <li>Persönlichkeitsstörungen</li>
                        <li>Essstörungen</li>
                        <li>Somatoforme und Schmerzstörungen</li>
                        <li>ADHS im Erwachsenenalter</li>
                        <li>Autismus-Spektrumsstörung (Asperger-Syndrom)</li>
                        <li>Stress, Burnout, Mobbing</li>
                        <li>Notfall, Krisen</li>
                        <li>Trauer</li>
                    </div>
                </b-col>

                <b-col class="col-sm-5 col-10">
                    <div class="content-field mt-3 mt-sm-0">
                        <h5>Verfahren</h5>
                        <ul>
                            <li>
                                <a style="" target="_blank"
                                   href="https://de.wikipedia.org/wiki/Verhaltenstherapie">
                                    Verhaltenstherapie
                                </a>
                            </li>
                            <li>
                                <a style="" target="_blank"
                                   href="https://de.m.wikipedia.org/wiki/Trauma_(Psychologie)#Traumatherapie">
                                    Traumatherapie
                                </a>
                            </li>
                            <li>Kurzzeittherapie</li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "TherapieSection"
    }
</script>

<style scoped>
    #section-offer {
        text-align: left;
    }

    .content-field {
    }

    ul {
        margin-left: -25px;
    }

    li {
        line-height: 2
    }

    .badge {
    }

</style>
