import { render, staticRenderFns } from "./image-carousel.vue?vue&type=template&id=87f1bd96&scoped=true&"
import script from "./image-carousel.vue?vue&type=script&lang=js&"
export * from "./image-carousel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87f1bd96",
  null
  
)

export default component.exports