<template>
    <div>
        <ueberschrift-section :hide-navigation="true"/>
        <b-container class="pt-5">
            <h2>
                <b-badge href="#/">
                    ← zurück
                </b-badge>
                Impressum
            </h2>
            <p style="text-align: right">
                Praxis für Psychotherapie <br>
                Dipl.-Psych. Dubravka Wild <br>
                Robert-Gerwig-Str. 19 <br>
                D-78141 Schönwald <br>
                Telefon: +49 157 3457 0368 <br>
                <a href="mailto:praxis@psychotherapiewild.de">praxis@psychotherapiewild.de</a> <br>
            </p>
            <h3>
                Berufsbezeichnung
            </h3>

            <p>
                Psychologische Psychotherapeutin
                Approbation: Regierungspräsidium Stuttgart
                Fachkundenachweis für Verhaltenstherapie bei Erwachsenen
                Arztregister-Nr.: 161452468
                Aufsichtsbehörden: Landespsychotherapeutenkammer Baden-Württemberg (LPK BW) - Körperschaft des
                öffentlichen Rechts, Jägerstr. 40, 70174 Stuttgart und Kassenärztliche Vereinigung Baden-Württemberg (KV
                BW), Bezirksdirektion Freiburg, Sundgauallee 27, 79114 Freiburg
            </p>

            <h3>
                Berufsrechtliche Regelungen
            </h3>

            <p>
                Gesetz über die Berufe des Psychologischen Psychotherapeuten und des Kinder- und
                Jugendlichen-Psychotherapeuten (Psychotherapeutengesetz - PsychThG) v. 16.06.1998 (BGBl. I S. 1311 ff.),
                zuletzt geändert am 18.04.2016 (BGBl I S. 886) und Heilberufe-Kammergesetz Baden-Württemberg (HBKG BW)
                in der Fassung vom 16.03.1995, zuletzt geändert am 17.12.2015 (GBI.BW v. 29.12.2015, S. 1234)
            </p>

            <h3>
                Haftungshinweis
            </h3>

            <p>
                Diese Internetpräsenz dient ausschließlich der allgemeinen Information.
                Sie kann und will keine gesundheitliche Beratung oder Behandlung ersetzen, da diese individuell und nach
                ausführlicher Untersuchung erfolgen müssen.
                Die Inhalte dieser Website wurden sorgfältig geprüft und nach bestem Wissen erstellt. Dennoch kann für
                die hier angebotenen Informationen kein Anspruch auf Vollständigkeit, Aktualität, Qualität und
                Richtigkeit erhoben werden. Es kann darüber hinaus keine Verantwortung für Schäden übernommen werden,
                die durch das Vertrauen auf die Inhalte dieser Website oder deren Gebrauch entsteht.
                Trotz sorgfältiger inhaltlicher Kontrolle übernehme ich keine Haftung für die Inhalte externer Links.
                Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
            </p>

            <h3>
                Inhaltlich verantwortlich
            </h3>

            <p>
                Dipl.-Psych. Dubravka Wild, Adresse siehe oben.
            </p>

            <h3>
                Datenschutzerklärung
            </h3>

            <p>
                Ich erhebe, verarbeite und nutze Ihre Daten nur im Rahmen der gesetzlichen Bestimmungen. Diese
                Datenschutzerklärung gilt ausschließlich für die Nutzung der von uns angegebenen Webseiten. Sie gilt
                nicht für die Webseiten anderer Diensteanbieter, auf die ich lediglich durch einen Link verweise. Bei
                der Nutzung meiner Webseiten bleiben Sie anonym, solange Sie mir nicht von sich aus freiwillig
                personenbezogene Daten zur Verfügung stellen. Personenbezogene Daten werden nur dann erhoben, wenn dies
                für die Nutzung der auf der Webseite angebotenen Leistung, insbesondere einem Formularangebot zur
                Kontaktaufnahme, erforderlich ist. Ich werde die von Ihnen zur Verfügung gestellten Daten streng
                vertraulich behandeln. Ohne Ihre ausdrückliche Einwilligung geben ich keine persönlichen Daten weiter,
                es sei denn, dass ich rechtlich dazu verpflichtet bin. Ich weise jedoch darauf hin, dass es bei der
                Übermittlung von Daten im Internet immer dazu kommen kann, dass Dritte Ihre Daten zur Kenntnis nehmen
                oder verfälschen. Sofern Sie es von mir verlangen, gewähre ich Ihnen Einblick in die über Sie
                gespeicherten Daten beziehungsweise lösche diese. Wenn Sie diese Daten berichtigen, löschen oder
                einsehen wollen, genügt hierfür ein Schreiben an die im Impressum angegebene Adresse.
            </p>

            <h3>
                Hinweis zu Google Analytics
            </h3>

            <p>
                Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google
                Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine
                Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen
                über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen
                und dort gespeichert.
                Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite, wird Ihre IP-Adresse von Google
                jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
                Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle
                IPAdresse an einen Server von Google in den USA übertragen und dort gekürzt.
                Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der
                Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der
                Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu
                erbringen.
                Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen
                Daten von Google zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende
                Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.Sie können
                darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen
                Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
                indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren. Für das
                Browser Plugin klicken Sie <a href="https://tools.google.com/dlpage/gaoptout?hl=de">hier</a>.
            </p>

            <h3>
                Webseite realisiert mit
            </h3>
            <ul>
                <li>Vue.js</li>
                <li>Bootstrap</li>
                <li>Bootstrap-vue</li>
                <li>Font Awesome</li>
            </ul>
            Code zu finden auf
            <a href="https://gitlab.com/jield/praxiswild-vue">gitlab</a> <br>
            Code unter der GPLv3; Verwendung der Bilder/Texte bitte anfragen

        </b-container>
    </div>
</template>

<script>
    import UeberschriftSection from "@/components/ueberschrift-section";

    export default {
        name: "ImpressumView",
        components: {UeberschriftSection},
        mounted () {
            /* dont keep scroll position from other view */
            window.scrollTo(0, 0)
        }
    }
</script>

<style scoped>
    p {
        text-align: left;
    }

    h3 {
        text-align: left;
    }

    li {
        text-align: left;
    }
</style>
