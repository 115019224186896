<template>
    <div id="section-offer">
        <b-container>
            <b-row>
                <b-col class="col-8 offset-sm-1 offset-1">
                    <h2>Ablauf</h2>
                </b-col>
            </b-row>

            <b-row class="d-flex justify-content-center">
                <b-col class="col-sm-5 col-10">
                    <div class="content-field">
                        <i style="float: left" class="fas fa-address-card mr-2"></i>
                        <h5>Kontaktaufnahme</h5>
                        <p>
                            Über <a href="#section-contact">Telefon und Email</a>.
                            Ich melde mich dann so <br> schnell wie möglich zurück.
                        </p>
                    </div>
                    <div class="content-field">
                        <i style="float: left" class="fas fa-couch mr-2"></i>
                        <h5> Erste Therapiesitzung</h5>
                        <p>Gesetzliche und private Krankenkassen übernehmen üblicherweise die ersten Sitzungen
                            antragsfrei, noch <br> bevor ein Therapieantrag gestellt wurde. </p>
                    </div>
                </b-col>

                <b-col class="col-sm-5 col-10">
                    <div class="content-field">
                        <i style="float: left" class="fas fa-comments mr-2"></i>
                        <h5>Erstgespräch</h5>
                        <p>Im Erstgespräch lernen wir uns kennen und besprechen das weitere Vorgehen.</p>
                    </div>
                    <div class="content-field">
                        <i style="float: left" class="fas fa-signature mr-2"></i>
                        <h5>Antrag bei der Krankenkasse</h5>
                        <p>Wenn Sie sich für eine Therapie entscheiden wird ein Antrag gestellt.
                            Eine Kurzzeittherapie umfasst 24 Sitzungen und eine
                            Langzeittherapie 60 Sitzungen á 50 Minuten.
                        </p>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "AblaufSection",
    }
</script>

<style scoped>
    #section-offer {
        text-align: left;
    }

    .content-field {
    }

</style>
