<template>
    <div id="sticky-div">
        <div>
            <h1>
                <b-badge class="d-sm-none ml-n4"
                         left
                         style="background-color: transparent"
                >
                    <navigation drop-down-menu="true"></navigation>
                </b-badge>

                <a href="#/" onclick="window.scrollTo(0,0);" style="text-decoration: none">
                    Psychotherapie Wild
                    <b-badge class="d-none d-md-inline-block"
                             style="position: absolute; background-color: transparent;">
                        <b-img-lazy class=""
                                    width="80"
                                    :src="require('@/assets/img/praxislogo.svg')"
                        />
                    </b-badge>

                    <br/>
                    <small class="d-none d-md-inline-block">in Schönwald</small>
                </a>
            </h1>

            <navigation-section v-if="! hideNavigation" class="d-none d-sm-block" active-link="Home"/>

        </div>

        <div id="hide-box-shadow"/>
    </div>
</template>

<script>
    import NavigationSection from "@/components/subcomponents/navigation-section";

    export default {
        name: "UeberschriftSection",
        components: {NavigationSection},
        props: {
            htmlText: String,
            hideNavigation: Boolean
        }
    }
</script>

<style scoped>
    #sticky-div {
        margin-top: 1rem;
        background-color: white;
        position: sticky;
        position: -webkit-sticky; /* Safari */
        top: 0;
        min-height: 50px;
        z-index: 80;
        box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.44);
    }

    #shadowed-div {
        margin-top: 1rem;
        background-color: white;
        position: sticky;
        position: -webkit-sticky; /* Safari */
        top: 0;
        z-index: 80;
        box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.44);
    }

    #hide-box-shadow {
        position: relative;
        top: 10px;
        height: 12px;
        z-index: 99;
    }
</style>
