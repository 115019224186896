<template>
    <div>
        <b-container>
            <b-row>
                <b-col class="offset-1">
                    <h2>Vorwort</h2>
                </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center">
                <b-col class="col-10">
                    <div class="content-field">
                        <p>
                            Herzlich willkommen auf der Webseite meiner Praxis für Psychotherapie in Schönwald.
                            Ich freue mich, dass Sie den Weg zu mir gefunden haben.

                            Als Diplom-Psychologin und approbierte Psychologische Psychotherapeutin behandle
                            ich psychische Erkrankungen bei Erwachsenen mit wissenschaftlich fundierten
                            und störungsspezifischen Therapieprogrammen.

                            Die Grundlage der einzeltherapeutischen Einzelsitzungen bildet die kognitive Verhaltenstherapie,
                            die zu den sogenannten Richtlinienverfahren zählt.
                        </p>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "WillkommenSection",
    }
</script>

<style scoped>
.content-field {
    text-align: justify;
}
    h2 {
        text-align: left;
    }

    p {
        line-height: 2;
    }

    @media screen and (max-width: 564px) {
        p {
            /* because justified text doesnt look good on small screens */
            text-align: left;
        }
    }
</style>
